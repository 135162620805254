@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer utilities {
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-200%);
    }
  }

  /* Base (for mobile) */
  .animate-marquee {
    animation: marquee 10s linear infinite;
    display: flex;
  }

  /* For tablets */
  @media (min-width: 768px) {
    .animate-marquee {
      animation-duration: 20s;
    }
  }

  /* For desktops */
  @media (min-width: 1024px) {
    .animate-marquee {
      animation-duration: 25s;
    }
  }
}
