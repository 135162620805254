body {
  font-family: "Inter";
  height: 100vh;
}

/* Define your variables directly in CSS */
:root {
  --toggler-icon-transform-time: 0.5s;
  --navbar-toggler-icon-bg: black;
}

.navbar-toggler {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler .toggler-icon {
  display: block;
  width: 20px;
  height: 2px;
  background-color: var(--navbar-toggler-icon-bg);
  margin: 6px 0;
  transition: transform var(--toggler-icon-transform-time) ease,
    opacity var(--toggler-icon-transform-time) ease;
}

/* Default hamburger state (three horizontal lines) */
.navbar-toggler .toggler-icon:nth-of-type(1) {
  transform: translateY(0);
}

.navbar-toggler .toggler-icon:nth-of-type(2) {
  opacity: 1;
}

.navbar-toggler .toggler-icon:nth-of-type(3) {
  transform: translateY(0);
}

/* Toggle state (cross) */
.navbar-toggler.collapsed .toggler-icon:nth-of-type(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.navbar-toggler.collapsed .toggler-icon:nth-of-type(2) {
  opacity: 0;
}

.navbar-toggler.collapsed .toggler-icon:nth-of-type(3) {
  transform: rotate(-45deg) translate(7px, -6px);
}
